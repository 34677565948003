import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import HeroPage from "../components/hero-page";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import logoCameronCo from "../assets/images/cameron-co-logo.svg";
import styled from "styled-components";
import { respondTo } from "../styles/respond-to";
import Seo from "../components/seo";

const About = ({ data }) => {
  return (
    <>
      <Seo
        title="About PA Six"
        description="The PA Six region of North Central Pennsylvania includes the counties of Cameron, Clearfield, Elk, Jefferson, McKean, and Potter."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="About us"
        caption="JCHC Building, Brookville, Jefferson County"
        position="25%"
      />

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Discover PA Six</h2>
          </div>
          <div className="intro-text">
            <p>
              <b>PA Six</b> is an initiative designed to increase awareness
              about the great career opportunities that exist in the counties of
              PA Six as well as promote the exceptional quality of life
              residents enjoy.
            </p>
            <p>
              <b>PA Six</b>, also known as <i>North Central Pennsylvania</i>,
              includes the counties of{" "}
              <Link to="/counties/cameron">Cameron</Link>,{" "}
              <Link to="/counties/clearfield">Clearfield</Link>,{" "}
              <Link to="/counties/elk">Elk</Link>,{" "}
              <Link to="/counties/jefferson">Jefferson</Link>,{" "}
              <Link to="/counties/mckean">McKean</Link>, and{" "}
              <Link to="/counties/potter">Potter</Link>.
            </p>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-title">
            <GatsbyImage
              image={getImage(data.wfLogo.sharp.image)}
              alt="Workforce Solutions"
              width={300}
            />
          </div>
          <div className="intro-text">
            <p>
              This initiative was led by{" "}
              <b>
                Workforce Solutions for North Central PA/the North Central
                Workforce Development Board
              </b>{" "}
              who formed an advisory team made up of employers, economic and
              community development agencies, chambers of commerce and other
              individuals to assist in the development and execution of the PA
              Six Marketing Campaign.
            </p>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-title">
            <h2>The PA Six brand</h2>
          </div>
          <div className="intro-text">
            <p>
              Through the <b>PA Six</b> brand we desire to raise awareness of PA
              Six as a career destination and ultimately attract and retain
              talented individuals so that PA Six employers can continue to
              thrive into the future.
            </p>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-title">
            <h2>Discover PA Six Advisory Team</h2>
          </div>
          <div className="intro-text">
            <BoardLogos>
              <GatsbyImage
                image={getImage(data.lumberLogo.sharp.image)}
                alt="Lumber Heritage Region"
                className="logo-img"
              />

              <img
                src={logoCameronCo}
                alt="Cameron County"
                className="logo-img"
              />

              <GatsbyImage
                image={getImage(data.baccLogo.sharp.image)}
                alt="Bradford Area Chamber of Commerce"
                className="logo-img"
              />

              <GatsbyImage
                image={getImage(data.northCentralLogo.sharp.image)}
                alt="North Central"
                className="logo-img"
              />
            </BoardLogos>
          </div>
        </section>
      </main>
    </>
  );
};

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "JCHC-Building-Front-1.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "JCHC-Building-Front-1.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

    wfLogo: file(
      relativePath: { eq: "Workforce-Solutions_logo_web-usage.png" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData(placeholder: TRACED_SVG)
      }
    }

    lumberLogo: file(relativePath: { eq: "LHR_Logo_Green_RGB.png" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(placeholder: TRACED_SVG)
      }
    }

    baccLogo: file(relativePath: { eq: "bradford-area-logo.png" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(placeholder: TRACED_SVG)
      }
    }

    northCentralLogo: file(relativePath: { eq: "north-central-300.png" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`;

const BoardLogos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  ${respondTo.sm`
      grid-template-columns: 1fr;
  `}

  .logo-img {
    max-width: 300px;
  }
`;
export default About;
